import request from '@/server/request'
import rootStore from '@/store'
import i18n from '@/plugins/i18n'

export default {
  async login({ dispatch }, credentials) {
    return request({ entity: 'user', action: 'login' }, null, 'POST', credentials)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
          .then(user => {
            if (user.id) {
              dispatch('setAccessToken', credentials.access_token)
            }
          })
        } else if (credentials.message) {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t(credentials.message),
            action1: { label: i18n.t('OK') }
          })
        }
      })
  },

  async requestPasswordReset(context, email) {
    return request({ entity: 'user', action: 'request_password_reset' }, null, 'POST', { email })
  },

  async resetPassword(context, payload) {
    return request({ entity: 'user', action: 'reset_password' }, null, 'POST', payload)
  },

  async register({ dispatch }, userData) {
    return request({ entity: 'user', action: 'register' }, null, 'POST', userData)
    .then(async credentials => {
      if (credentials.token_type === 'Bearer' && credentials.access_token) {
        await dispatch('loadUserData', credentials.access_token)
        .then(user => {
          if (user.id) {
            dispatch('setAccessToken', credentials.access_token)
            return credentials.access_token
          }
        })
      }
    })
  },

  async loadUserData({ commit }, token) {
    return request({ entity: 'user', action: 'me' }, token, 'GET')
      .then(user => {
        commit('setUser', user)
        return user
      })
  },

  async loadUserGeneralInformation({ commit }, { token }) {
    return request({ entity: 'user.general_information', action: 'get' }, token, 'GET')
      .then(res => {
        if (res.status === 'success') {
          commit('setUserGeneralInformation', res.result)
        }
        return res
      })
  },

  saveGeneralSection({commit}, payload) {
    commit('saveGeneralSection', payload);
  },

  saveContentSection({commit}, payload) {
    commit('saveContentSection', payload)
  },

  saveDeliverySection({commit}, payload) {
    commit('saveDeliverySection', payload)
  },

  saveScheduleRegularSection({commit}, payload) {
    commit('saveScheduleRegularSection', payload)
  },

  saveScheduleSelfPickupSection({commit}, payload) {
    commit('saveScheduleSelfPickupSection', payload)
  },

  saveScheduleSpecialSection({commit}, payload) {
    commit('saveScheduleSpecialSection', payload)
  },

  updatePartner({commit}, { token, payload }) {
    let formData = new FormData();
    formData.append('data', JSON.stringify(payload))
    formData.append('partner_name', payload.general.name)
    formData.append('logo_path', payload.general.logo_path)
    return request({entity:'user.general_information', action:'update'}, token, 'POST', formData, 'multipart/form-data')
      .then(response => {
        if (response.status === 'success') {
          commit('setUserGeneralInformation', response.result);
        }

        return response
      }).catch(error => {
        return error.response
      })
  },

  // change logo and banner images
  uploadImage ({ commit }, { token, image, type }) {
    let formData = new FormData();
    formData.append(`file`, image, image.name)
    formData.append(`type`, type)
    return request({entity:'user', action:'upload_image'}, token, 'POST', formData, 'multipart/form-data')
      .then(response => {
        if (type == 'logo_file') {
          commit('setUserLogo', response.path);
        }
        return response
      }).catch(error => {
        return error.response
      })
  },

  setAccessToken({ commit }, token) {
    commit('setAccessToken', token)
  },

  logout({ commit }) {
    commit('resetUser');
  }
}
