export default {
  setUser (state, user) {
    state.user = user
  },
  setUserLogo (state, logo) {
    state.user.partner.logo_path = logo
  },
  setAccessToken (state, token) {
    localStorage.setItem('access_token', token)
    state.access_token = token
  },
  resetUser (state) {
    state.user = ''
    state.access_token = ''
    localStorage.removeItem('access_token')
  },
  setUserGeneralInformation (state, genInfo) {
    state.general_information = genInfo;
  },
  saveGeneralSection (state, payload) {
    state.general_information.general = payload
  },
  saveContentSection (state, payload) {
    state.general_information.content = payload
  },
  saveDeliverySection (state, payload) {
    state.general_information.delivery.delivery_available = payload.available_options.delivery_available;
    state.general_information.delivery.asap_available = payload.available_options.asap_available;
    state.general_information.delivery.pickup_available = payload.available_options.pickup_available;
    state.general_information.delivery.working_schedule = payload.working_schedule;
  },
  saveScheduleRegularSection (state, payload) {
    state.general_information.schedule.regular = payload.regular
  },
  saveScheduleSelfPickupSection (state, payload) {
    state.general_information.schedule.pickup = payload.pickup
  },
  saveScheduleSpecialSection (state, payload) {
    state.general_information.schedule.special = payload.special
  },
}
