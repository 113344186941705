export default {
  getWorkingSchedule: state => state.workingSchedule,

  getSpecialSchedule: state => state.specialSchedule,

  addedDaysCount: state => {
    const addedDays = {
      working: 0,
      special: 0
    }
    if (Array.isArray(state.specialSchedule)) {
      addedDays.special = state.specialSchedule.length
    }
    if (Array.isArray(state.workingSchedule)) {
      addedDays.working = state.workingSchedule.reduce((total, workingDay) => {
        return workingDay.hours.length ? total + 1 : total + 0
      }, 0)
    }

    return addedDays
  },

  getDefaultFoodTimeRanges: state => {
    state.defaultTimeRanges = [
      { from: '10:00', to: '11:00' },
      { from: '11:01', to: '12:00' },
      { from: '12:01', to: '13:00' },
      { from: '13:01', to: '14:00' },
      { from: '14:01', to: '15:00' },
      { from: '15:01', to: '16:00' },
      { from: '16:01', to: '17:00' },
      { from: '17:01', to: '18:00' },
      { from: '18:01', to: '19:00' },
      { from: '19:01', to: '20:00' },
      { from: '20:01', to: '21:00' },
      { from: '21:01', to: '22:00' }
    ]

    return state.defaultTimeRanges
  },

  getDefaultPickupTimeRanges: state => {
    state.defaultTimeRanges = [
      { from: '10:00', to: '10:15' },
      { from: '10:16', to: '10:30' },
      { from: '10:31', to: '10:45' },
      { from: '10:46', to: '11:00' },
      { from: '11:01', to: '11:15' },
      { from: '11:16', to: '11:30' },
      { from: '11:31', to: '11:45' },
      { from: '11:46', to: '12:00' },
      { from: '12:01', to: '12:15' },
      { from: '12:16', to: '12:30' },
      { from: '12:31', to: '12:45' },
      { from: '12:46', to: '13:00' },
      { from: '13:01', to: '13:15' },
      { from: '13:16', to: '13:30' },
      { from: '13:31', to: '13:45' },
      { from: '13:46', to: '14:00' },
      { from: '14:01', to: '14:15' },
      { from: '14:16', to: '14:30' },
      { from: '14:31', to: '14:45' },
      { from: '14:46', to: '15:00' },
      { from: '15:01', to: '15:15' },
      { from: '15:16', to: '15:30' },
      { from: '15:31', to: '15:45' },
      { from: '15:46', to: '16:00' },
      { from: '16:01', to: '16:15' },
      { from: '16:16', to: '16:30' },
      { from: '16:31', to: '16:45' },
      { from: '16:46', to: '17:00' },
      { from: '17:01', to: '17:15' },
      { from: '17:16', to: '17:30' },
      { from: '17:31', to: '17:45' },
      { from: '17:46', to: '18:00' },
      { from: '18:01', to: '18:15' },
      { from: '18:16', to: '18:30' },
      { from: '18:31', to: '18:45' },
      { from: '18:46', to: '19:00' },
      { from: '19:01', to: '19:15' },
      { from: '19:16', to: '19:30' },
      { from: '19:31', to: '19:45' },
      { from: '19:46', to: '20:00' },
      { from: '20:01', to: '20:15' },
      { from: '20:16', to: '20:30' },
      { from: '20:31', to: '20:45' },
      { from: '20:46', to: '21:00' },
      { from: '21:01', to: '21:15' },
      { from: '21:16', to: '21:30' },
      { from: '21:31', to: '21:45' },
      { from: '21:46', to: '22:00' }
    ]

    return state.defaultTimeRanges
  },

  // getDefaultShopSchedule: () => {
  //   return [
  //     { from: '11:01', to: '13:00' },
  //     { from: '13:01', to: '15:00' },
  //     { from: '15:01', to: '17:00' },
  //     { from: '17:01', to: '19:00' },
  //     { from: '19:01', to: '21:35' }
  //   ]
  // },
}
