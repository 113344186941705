import _ from 'lodash'
export const WORKING_SCHEDULE = [
  { title: 'Sunday', day: 'sunday', hours: [] },
  { title: 'Monday', day: 'monday', hours: [] },
  { title: 'Tuesday', day: 'tuesday', hours: [] },
  { title: 'Wednesday', day: 'wednesday', hours: [] },
  { title: 'Thursday', day: 'thursday', hours: [] },
  { title: 'Friday', day: 'friday', hours: [] },
  { title: 'Saturday', day: 'saturday', hours: [] },
]
export default {
  workingSchedule: _.cloneDeep(WORKING_SCHEDULE),
  specialSchedule: [],
  defaultTimeRanges: []
}
