import './filters'
import store from './store'
import router from './router'
import axios from 'axios'

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/wysiwyg'
import wysiwyg from 'vue-wysiwyg'
import i18n from "./plugins/i18n";
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

Vue.config.productionTip = false
Vue.use(wysiwyg, {})
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = process.env['VUE_APP_API_URL']
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json'

Vue.prototype.$vsfApiHttp = axios.create({
  baseURL: process.env['VUE_APP_VSF_API_URL'],
  timeout: process.env['VUE_APP_VSF_API_TIMEOUT'],
  headers: { 'Content-Type': 'application/json' }
})

const token = localStorage.getItem('access_token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

Vue.prototype.$http.interceptors.response.use(undefined, async error => {
  return new Promise(() => {
    if (error.response.status === 401 && error.config && !error.config.__isRetryRequest && error.response.data !== 'Your credentials are incorrect. Please try again' && error.response.data.message.substring(0, 12) !== 'Client error') {
      store.dispatch('clearLocalAuthData').then(() => {
        router.push('/login')
      })
      return false
    }
    throw error
  })
})

/**
 * We will use another Vue instance as a global
 * event handler (subscribe / emit events)
 */
Vue.prototype.$bus = new Vue()

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
