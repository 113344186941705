export default {
  getAccessToken: state => state.access_token,
  getUser: state => state.user,
  getUserPartner: state => state.user.partner,
  getUserGeneralInformation: state => state.general_information,
  getUserGeneralDataInformation: state => state.general_information.general,
  getContentInformation: state => state.general_information.content,
  getUserDeliveryInformation: state => state.general_information.delivery,
  getUserScheduleInformation: state => state.general_information.schedule,
}
