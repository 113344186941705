export const USER_FIELDS = {
  id: null,
  name: null,
  email: null
}

export default {
  user: USER_FIELDS,
  access_token: null,
  general_information: {
    general: {
      name: '',
      about: '',
      active: true,
      minimal_order_amount: 0,
      logo_path: '',
      logo_file: null
    },
    content: {
      // banner: '',
      // banner_file: null,
      cutlery_availability: 0,
      all_tags: [],
      selected_tags: []
    },
    delivery: {
      delivery_available: 1,
      pickup_available: 1,
      asap_available: 1,
      working_schedule: []
    },
    schedule: {
      regular: [],
      pickup: [],
      special: []
    }
  },
}
