<template>
  <section class="mobile-menu" :class="{'left-menu': !draw}">
    <v-navigation-drawer v-model="draw">
      <v-list class="mobile-menu__root" dense nav>
        <template v-for="(item, i) in items">
          <v-list-item v-if="item.allowed && !item.child" :key="i" :to="item.route" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else-if="item.allowed" :key="i" no-action>
            <v-list-item slot="activator" class="pl-0">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-for="(child, j) in item.child" :key="j" :to="child.route" link>
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(child.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navigation',
  data () {
    return {
      draw : true
    }
  },
  props: {
    menuShow: {
      type: Boolean
    }
  },
  mounted () {
    this.draw = this.menuShow
  },
  computed: {
    ...mapGetters({
      customer: 'user/getUser'
    }),
    items () {
      return [
        { title: 'Home', icon: 'mdi-home', route: '/', allowed: this.authenticated },
        {
          title: 'Catalog', icon: 'mdi-format-list-bulleted', child: [
            { title: 'Products', icon: 'mdi-hamburger', route: '/catalog/products', allowed: this.authenticated },
            { title: 'Product Options', icon: 'mdi-apps', route: '/catalog/product/options', allowed: this.authenticated },
            { title: 'Categories', icon: 'mdi-carrot', route: '/catalog/categories', allowed: this.authenticated }
          ],
          route: '/', allowed: this.authenticated },
        { title: 'General Information', icon: 'mdi-apps', route: '/general-information', allowed: true },
      ]
    },
    authenticated () {
      return !!this.customer.id
    }
  },
  watch: {
    draw () {
      this.$emit('changesValueMenu', this.draw)
    },
    menuShow () {
      this.draw = this.menuShow
    }
  }
}
</script>
<style lang="scss" scoped>
.v-toolbar__title a {
  text-decoration: none;
}

.toolbar-bg {
  /*background: rgb(0,191,204);*/
  background: #fbc02d;
}

.v-datatable tbody tr {
  cursor: pointer;
}
.mobile-menu {
  @media (max-width: 1260px) {
    position: absolute;
    z-index: 2;
    height: 100%;
  }

  &__root {
    padding: 16px;
  }
}
.left-menu {
  @media (max-width: 1260px) {
    animation-duration: 1s;
    animation-name: pulse;
    transform: translateX(-100%);
  }
}
@keyframes pulse {
  0% {transform: translateX(0);}
  100%  {transform: translateX(-100%);}
}
</style>
