import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import schedule from './schedule'

export default {
  modules: {
    schedule
  },
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
