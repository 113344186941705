import * as configDefault from '@/config/default.json'

let config = configDefault.default

export default function (
  { entity = null, action = null },
  token = null,
  method = 'GET',
  body = null,
  contentType = 'application/json',
) {
  let url = buildServerUrl(entity, action)

  let requestConfigs = {
    method,
    headers: {
      'Accept': contentType === 'multipart/form-data' ? '*/*' : contentType,
      ...(contentType !== 'multipart/form-data' && {
        'Content-Type': contentType
      })
    }
  }
  if (token) {
    requestConfigs.headers['Authorization'] = `Bearer ${token}`
  }
  let keys = url.match(/({{)(\w*)(}})/g)
  if (keys) {
    keys.forEach(key => {
      let propertyName = key.replace(/{|}/g, '')
      url = url.replace(key, body[propertyName])
      delete body[propertyName]
    })
  }
  url = (body && method === 'GET') ? processUrlParams(url, body) : url
  if (body && method !== 'GET') {
    body = (body && typeof body !== 'string' && contentType !== 'multipart/form-data') ? JSON.stringify(body) : body
    requestConfigs.body = body
  }
  if (method === 'DELETE') {
    return fetch(url, requestConfigs)
  }

  return fetch(url, requestConfigs).then(res => res.json())
}

export function buildServerUrl(entity, action) {
  if (entity.includes('.')) {
    return `${entity.split('.').reduce((o,i) => o[i], config)[action]}`
  }
  validateUrlParams(entity, action)
  return `${config[entity][action]}`
}

function validateUrlParams(entity, action, object = config) {
  if (!object[entity]) {
    throw new Error(`Requested entity ${entity} was not found in Portal configs`)
  }
  if (!object[entity][action]) {
    throw new Error(`Requested action ${action} was not found in Portal configs entity ${entity}`)
  }
}

function processUrlParams (baseUrl, params, firstItemProcessed = false) {
  Object.keys(params).forEach(key => {
    let char = firstItemProcessed ? '&' : '?'
    firstItemProcessed = true
    baseUrl = `${baseUrl}${char}${key}=${params[key]}`
  })

  return baseUrl
}