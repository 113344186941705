import { WORKING_SCHEDULE } from './state'

import Vue from 'vue'
import _ from 'lodash'

export default {
  setSchedule (state, schedule) {
    if (Array.isArray(schedule.working)) {
      const newWorkingSchedule = _.cloneDeep(WORKING_SCHEDULE)
      const workingSchedule = {}
      schedule.working.forEach(item => {
        workingSchedule[item.day] = item.hours
      })
      newWorkingSchedule.forEach(workingDay => {
        if (typeof workingSchedule[workingDay.day] !== 'undefined') {
          workingDay.hours = workingSchedule[workingDay.day]
        }
      })

      state.workingSchedule = newWorkingSchedule
    }
    state.specialSchedule = schedule.special
  },

  cleanSchedule (state) {
    state.workingSchedule = _.cloneDeep(WORKING_SCHEDULE)
    state.specialSchedule = []
  },

  deleteWorkingHour (state, hour) {
    if (!Array.isArray(hour) || hour.length !== 2) {
      return
    }
    const dayNumber = hour[0]
    const hours = hour[1]
    if (typeof state.workingSchedule[dayNumber] === 'undefined') {
      return
    }
    const newHours = state.workingSchedule[dayNumber].filter(item => {
      if (item.from !== hours.from) {
        return item
      }
    })
    Vue.set(state.workingSchedule, dayNumber, newHours)
  },

  deleteSpecialHour (state, hour) {
    if (!Array.isArray(hour) || hour.length !== 2) {
      return false
    }
    const day = hour[0]
    const hours = hour[1]
    const specialDay = state.specialSchedule.find(item => item.day === day)
    if (!specialDay) {
      return false
    }
    specialDay.hours = specialDay.hours.filter(item => {
      if (item.from !== hours.from) {
        return item
      }
    })

    return true
  }
}
