export default {
  set(state, addressesList) {
    state.addressesList = addressesList;
  },
  push (state, payload) {
    state.addressesList.push(payload);
  },
  update(state, payload) {
    state.addressesList.splice(state.addressesList.indexOf(state.addressesList.find(address => address.id === payload.id)), 1, payload);
  }
};
