import Vue from 'vue'
import Router from 'vue-router'

import auth from './middleware/auth'
import guest from './middleware/guest'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './pages/Dashboard.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: '/catalog/product/options',
      name: 'catalog-product-options',
      component: () => import(/* webpackChunkName: "catalog-product-options" */ './pages/Catalog/ProductOptions.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: '/catalog/products',
      name: 'catalog-products',
      component: () => import(/* webpackChunkName: "catalog-products" */ './pages/Catalog/Products.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: '/catalog/categories',
      name: 'catalog-categories',
      component: () => import(/* webpackChunkName: "catalog-categories" */ './pages/Catalog/Categories.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ './pages/Auth/Login.vue'),
      meta: {
        middleware: guest
      }
    },
    {
      path: '/general-information',
      name: 'general-information',
      component: () => import(/* webpackChunkName: "general-information" */ './pages/GeneralInformation.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: '/reset-password-request',
      name: 'reset-password-request',
      component: () => import(/* webpackChunkName: "auth" */ './pages/Auth/ResetPasswordRequest.vue'),
      meta: {
        middleware: guest
      }
    },
    {
      path: '/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "auth" */ './pages/Auth/ResetPassword.vue'),
      meta: {
        middleware: guest
      }
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "pagenotfound" */ './pages/PageNotFound.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: '*',
      redirect: '/404',
      meta: {
        middleware: auth
      }
    }
  ]
})

// We have to implement a Middleware to allow user to access certain routes
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }

    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
