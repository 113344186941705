<template>
  <v-app-bar class="toolbar-bg justify-center" dark app>
    <v-col class="mobile-button">
      <v-app-bar-nav-icon class="md6 button" @click.stop="showOrHidden"/>
      <div class="desktop-logo-portal">
        <h3>Korzyna</h3>
        <p>Portal</p>
      </div>
    </v-col>
    <v-col class="logout">
      <v-menu offset-y v-model="openAccountMenu">
        <template v-slot:activator="{ on }">
          <v-btn
            class="account-button"
            v-on="on"
          >
            <v-icon :class="{'open-menu': openAccountMenu}">mdi-menu-down</v-icon>
            <span class="user-name">{{ currentUser.name }}</span>
            <v-avatar size="32">
              <img :src="logoPartner" alt="">
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content>
            <div class="mx-auto">
              <v-btn
                class="account-menu-buttons"
                depressed
                rounded
                text
              >
                <v-icon>mdi-account</v-icon> {{ $t('My Profile') }}
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                class="account-menu-buttons"
                depressed
                rounded
                text
                @click="logout"
              >
                <v-icon>mdi-logout</v-icon> {{ $t('Logout') }}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-col>
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  data () {
    return {
      openAccountMenu: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUser'
    }),
    logoPartner () {
      return this.currentUser.partner.logo_path ? process.env.VUE_APP_BASE_IMAGE_URL + this.currentUser.partner.logo_path : 'https://www.korzyna.com/assets/placeholder.svg'
    }
  },
  props: {
    menuShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login')
      })
    },
    showOrHidden () {
      this.$emit('changesValueMenu', !this.menuShow)
    }
  },
}
</script>

<style lang="scss" scoped>
.v-toolbar__title a {
  text-decoration: none;
}

.toolbar-bg {
  background: rgb(0,191,204);
  background: linear-gradient(132deg, rgba(0,191,204,1) 0%, rgba(58,123,167,1) 100%);
  background: #fbc02d!important;
}

.v-datatable tbody tr {
  cursor: pointer;
}

.xs-avatar {
  margin-right: -16px;
}
.mobile-button {
  flex-basis: 50%;
  padding: 0;
  height: 100%;
  .button {
    display: none;
    @media (max-width: 1200px) {
      display: block;
      position: absolute;
      left: 0;
    }
  }
  .desktop-logo-portal {
    display: block;
    color: #000;
    @media (max-width: 1200px) {
      display: none;
    }
    h3 {
      font-size: 20px;
    }
  }
}
.logo-partner {
  //text-align: center;
  //justify-content: center;
  max-width: 120px;
  max-height: 54px;
}
.col-xs-4 {
  @media (max-width: 600px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.col-xs-8 {
  @media (max-width: 600px) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
.logo {
  flex-basis: 50%;
  text-align: center;
}
.logout {
  flex-basis: 50%;
  text-align: end;
  cursor: pointer;
  position: relative;
}
.account-button {
  box-shadow: 0 1px 2px 0 #444 inset, 0 0 0 6px #fbc031, 0 2px 12px 8px #fbc031;
}
.open-menu {
  transform: rotateX(180deg);
}
.user-name {
  margin-right: 5px;
  @media (max-width: 1200px) {
    display: none;
  }
}
.account-menu-buttons {
  width: 100%;
}
</style>
<style>
.toolbar-bg .v-toolbar__content {
  justify-content: center;
  position: relative;
}
.account-menu-buttons .v-btn__content {
  justify-content: flex-start;
}
</style>
