import request from "@/server/request";

export default {
  async loadPartnerAddressesList({ commit }, { token, per_page, page }) {
    return request({entity: 'partner.address.list', action: 'get'}, token, 'GET', {per_page, page})
      .then(res => {
        if (res.data) commit('set', res.data);
        return res.data;
      });
  },
  async addPartnerAddress ({ commit }, { token, address }) {
    return request({entity: 'partner.address', action: 'create'}, token, 'POST', {...address})
      .then(res => {
        commit('push', res.data);
      });
  },
  async updatePartnerAddress ({ commit }, { token, address }) {
    return request({entity: 'partner.address', action: 'update'}, token, 'PATCH', {address_id: address.id, ...address})
      .then(res => {
        commit('update', res.data);
        return res.data;
      });
  }
};
