import address from "@/store/partner/address";

export default {
  modules: {
    address
  },
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {}
}
