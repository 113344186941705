export default {
  addWorkingHour (store, working) {
    let workingDay = store.state.workingSchedule.find(item => item.day === working.day)
    if (!workingDay || !Array.isArray(workingDay.hours)) {
      workingDay.hours = []
    }
    const isTimeExists = workingDay.hours.some(item => item.from === working.hours.from)
    if (!isTimeExists) {
      workingDay.hours.push(working.hours)
    }
  },

  addSpecialDay (store, day) {
    const isExists = store.state.specialSchedule.some(item => {
      return item.day === day
    })
    if (isExists) {
      // Special date already added. So return false instead of adding new
      return false
    }

    store.state.specialSchedule.push({ day: day, hours: [] })

    return true
  },

  addSpecialHour (store, special) {
    const specialDay = store.state.specialSchedule.find(item => {
      return item.day === special.day ? item : null
    })
    if (!specialDay) {
      return false
    }
    specialDay.hours.push(special.hours)

    return true
  },

  /**
   * Append schedule info to Partner before save
   *
   * @event before-partner-save
   * @param state
   * @param rootState
   */
  append: ({ state, rootState }) => {
    if (!state.workingSchedule.length && !state.specialSchedule.length) {
      return
    }
    // Send working days which have at least one filled working hours
    const filledWorkingDays = state.workingSchedule.filter(workingDay => workingDay.hours.length)

    rootState.partner.data.schedule = {
      working: filledWorkingDays,
      special: state.specialSchedule
    }
  }
}
